
import islmaicCenter from "../../../assets/images/background/islamic.PNG";
import { DayPicker } from "react-day-picker";

const AppointmentBanner = ({selectedDate,setSelectedDate}) => {
  return (
    <header className="my-6">
      <div className='text-center'>
                <h3 className='text-xl font-bold text-primary uppercase'>Events Page</h3>
                <h2 className='text-3xl'>Donate to charity causes
                    around the world</h2>
            </div>
      <div className="hero">
        
        <div className="hero-content flex-col lg:flex-row-reverse">
          <img
            src={islmaicCenter}
            className="max-w-sm lg:w-1/2 rounded-lg shadow-2xl"
            alt="dentist chair"
          />
          <div className="mr-6">
            <DayPicker
              mode="single"
              selected={selectedDate}
              onSelect={setSelectedDate}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default AppointmentBanner;
