import React, { useState } from 'react';
import quote from '../../../assets/icons/quote.svg';

import whatwedoone from '../../../assets/images/whatwedoone.png';
import educate from '../../../assets/images/whatwedotwo.png';
import campaign from '../../../assets/images/whatwedothree.png';
import expose from '../../../assets/images/whatwedofour.png';
import advocacy from '../../../assets/images/whatwedofive.png';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper";
import WaysToVolCard from './WaysToVolCard';
const WaysToVolunteer = () => {
    const reviews = [
        {
            _id: 1,
            name: 'Research',
            img: whatwedoone,
            review: 'Many people (students, professionals and others) have helped us prepare our country and issue reports. Their contributions have included writing full reports, searching for source material, proofreading, and formatting. '
        },
        {
            _id: 2,
            name: 'Advocacy',
            img: educate,
            review: 'In addition to setting up a local awareness event, you can help both the campaigns and advocacy area with your  skills. We are looking for qualified personnel who can communicate eloquently with government officials.',
            location: 'California'
        },
        {
            _id: 3,
            name: 'Fundraising',
            img: campaign,
            review: 'Like other charities, MUHA -JHD relies on the goodwill of its supporters. Volunteers can recruit new financial supporters or raise one-off donations to help JHD  meet the needs of the community. Occasionally JHD raises money for specific projects but mainly it raises general funds to be allocated according to need.',
            location: 'California'
        },
        {
            _id: 4,
            name: 'Events',
            img: expose,
            review: 'MUNA JHD organizes  meetings and seminars. Volunteers are often needed to steward, prepare and design flyers, communicate with potential participants, social media promotions, etc. These events usually, although not exclusively, take place in the USA, and involve different levels of commitment ',
            location: 'California'
        },
        {
            _id: 4,
            name: 'Raise awareness',
            img: advocacy,
            review: 'Many of the groups and individuals who have interest in volunteering for JHD  underte  to distribute our literature at their own reach etc. at a variety of events around the country. Their contribution helps to spread awareness of JHD, but more importantly the issues which the community needs to address. Again, this can be an afternoon’s work or several days of organising.',
            location: 'California'
        }
    ]

    const [swipers, setSwipers] = useState([
        "Slide 1",
        "Slide 2",
        "Slide 3",
        "Slide 4"
    ]);

    const addSwiper = (swiper) => {
        setSwipers((prevSwipers) => prevSwipers.concat(swiper));
    };
    return (
        <section className='my-10'>
            <div className='flex justify-between'>
                <div>
                    <h4 className="text-2xl text-primary font-bold">Ways to Volunteer</h4>
                    <h2 className="text-xl">Our work consists of raising awareness and educating communities on issues of social justice and human dignity. In order to acheive our goal, we focus on the following key areas: </h2>
                </div>
                <figure>
                    <img className='w-24 lg:w-48' src={quote} alt="" />
                </figure>
            </div>
            <>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={3}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                >
                    {
                        reviews.map((review, index) => (
                            <SwiperSlide key={index}>
                                <WaysToVolCard
                                    key={review._id}
                                    review={review}
                                >
                                </WaysToVolCard>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </>

      
        </section>
    );
};

export default WaysToVolunteer;