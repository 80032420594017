import React from 'react';
import clock from '../../../assets/icons/clock.svg';
import marker from '../../../assets/icons/marker.svg';
import phone from '../../../assets/icons/phone.svg';
import ActivityCard from './ActivityCard';
const OurActivities = () => {
    const cardData = [
        {
            id: 1,
            name: 'A. Organize event on Social Justice ',
            description: 'Organize event on Social Justice and we are dedicated to raise awareness against injustices and protect human rights and dignity around the world.  ',
            icon: clock,
            bgClass: 'bg-gradient-to-r from-primary to-secondary'
        },
        {
            id: 2,
            name: 'B. Fight for Change: ',
            description: 'Fight for Change: We meet with governments, affiliated organizations,  corporations, and others to see that policy is changed, laws are enforced, and justice is served.',
            icon: marker,
            bgClass: 'bg-accent'
        },
        {
            id: 3,
            name: 'C. Petitions',
            description: 'C.Through petitions, letters and protests, we press for action from the governments officials ',
            icon: phone,
            bgClass: 'bg-gradient-to-r from-primary to-secondary'
        },
        {
            id: 4,
            name: 'D. Campaign',
            description: 'We campaign and organize programs,  seminars and symposiums to educate and mobilize concerned people like you to join the to project justice and human rights and dignity. In addition  we write  letters and organize events to protest against injustices. ',
            icon: phone,
            bgClass: 'bg-gradient-to-r from-primary to-secondary'
        },
        {
            id: 5,
            name: 'E. Stories',
            description: 'We  tell the stories of what we found, sharing them with millions of social media and online followers each day. News media often report on our investigations, furthering our reach.',
            icon: phone,
            bgClass: 'bg-accent'
        },
        {
            id: 5,
            name: 'F. Injustices',
            description: 'We identify, investigate  and expose injustices happening in all corners of the world. We are a group of concerned citizens around the nation working to protect the most at risk, minorities  and civilians who face injustices.',
            icon: phone,
            bgClass: 'bg-gradient-to-r from-primary to-secondary'
        },
    ]
    return (
        <div className='mt-16' id='success'>
            <div className='text-center'>
                <h3 className='text-xl font-bold text-primary uppercase underline'>Our Activities</h3>
                <h2 className='text-3xl'>Donate to charity causes
                    around the world</h2>
            </div>

            <div className='grid mt-8 gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                {
                    cardData.map(card => <ActivityCard
                        key={card.id}
                        card={card}
                    ></ActivityCard>)
                }
            </div>
        </div>
    );
};

export default OurActivities;