import React from 'react';

const Activity = ({ review }) => {
    const { name, img, review: userReview, location } = review;
    return (
        <div className="card shadow-xl">
            <div className="card-body">
                <div className="flex items-center mt-6">
                    <div className="avatar mr-6">
                        <div className="w-16 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                            <img src={img} alt="" />
                        </div>
                    </div>
                    <div>
                        <h5 className="text-lg text-secondary font-bold">{name}</h5>
                    </div>
                </div>
                <p>{userReview}</p>

            </div>
        </div>
    );
};

export default Activity;