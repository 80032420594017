import React, { useState } from 'react';
import quote from '../../../assets/icons/quote.svg';

import people1 from '../../../assets/images/people1.png';
import people2 from '../../../assets/images/people2.png';
import people3 from '../../../assets/images/people3.png';

import whatwedoone from '../../../assets/images/whatwedoone.png';
import educate from '../../../assets/images/whatwedotwo.png';
import campaign from '../../../assets/images/whatwedothree.png';
import expose from '../../../assets/images/whatwedofour.png';
import advocacy from '../../../assets/images/whatwedofive.png';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper";
import Activity from './Activity';

const ActivityWeDo = () => {

    const reviews = [
        {
            _id: 1,
            name: 'Research and Investigate',
            img: whatwedoone,
            review: 'Our fight for justice and human dignity starts with the facts. We have a group of concernd  citizen around the nation who starts with  performing  research, identify the issues, gather support and  dig up evidence on injustice that are taking palace on civilians. These researches go through accuracy and  cross-checked and cultivate  information to help gather resources, conduct interviews and finally communicate with media to  publish accurate and reliable reports that catalogue witness testimonies.'
        },
        {
            _id: 2,
            name: 'Educate',
            img: educate,
            review: 'In order to bring positive change in society, we initiated programs to educate people. Through education programs  and events  we strive to bring a change in the world through education and mobilization. Our education program primarily focuses on  injustice and Human dignity issues around the globe . ',
            location: 'California'
        },
        {
            _id: 3,
            name: 'Campaign',
            img: campaign,
            review: 'we organize programs,  seminars and symposiums to educate and mobilize concerned people like you to join the to project justice and human rights and dignity. In addition  we write  letters and organize events to protest against injustices.',
            location: 'California'
        },
        {
            _id: 4,
            name: 'Expose',
            img: expose,
            review: 'Once the information is gathered: We begin to  tell the stories of what we found, sharing them with millions of social media and online followers each day. News media often report on our investigations, furthering our reach.',
            location: 'California'
        },
        {
            _id: 4,
            name: 'Advocacy',
            img: advocacy,
            review: 'Our advocacy involves communicating with government officials and international organizations to uphold juices for all.  Further, our advocacy towards governments encourages officials to change or enforce  laws, policies and practices. We also partner with organizations large and small across the globe to protect human rights  and to help hold the oppressor to account and bring justice to all victims.',
            location: 'California'
        }
    ]

    const [swipers, setSwipers] = useState([
        "Slide 1",
        "Slide 2",
        "Slide 3",
        "Slide 4"
    ]);

    const addSwiper = (swiper) => {
        setSwipers((prevSwipers) => prevSwipers.concat(swiper));
    };

    return (
        <section className='my-10'>
            <div className='flex justify-between'>
                <div>
                    <h4 className="text-2xl text-primary font-bold">What We Do</h4>
                    <h2 className="text-xl">Our work consists of raising awareness and educating communities on issues of social justice and human dignity. In order to acheive our goal, we focus on the following key areas: </h2>
                </div>
                <figure>
                    <img className='w-24 lg:w-48' src={quote} alt="" />
                </figure>
            </div>
            <>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={3}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                >
                    {
                        reviews.map((review, index) => (
                            <SwiperSlide key={index}>
                                <Activity
                                    key={review._id}
                                    review={review}
                                >
                                </Activity>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </>

      
        </section>
    );
};

export default ActivityWeDo;