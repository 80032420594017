import React from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from "@tanstack/react-query";
import Loading from '../../Shared/Loading/Loading';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const AddNews = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const imageHostKey = process.env.REACT_APP_imgbb_key;

    const navigate = useNavigate();

    const handleAddNews = data => {
        const image = data.image[0];
        const formData = new FormData();
        formData.append('image', image);
        const url = `https://api.imgbb.com/1/upload?key=${imageHostKey}`;
        fetch(url, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(imgData => {
                if (imgData.success) {
                    console.log(imgData.data.url);
                    const news = {
                        name: data.name,
                        news: data.news,
                        image: imgData.data.url,
                        createdDate: new Date()
                    }

                    // save doctor information to the database
                    fetch('https://jhd-donation-mahsin-islam.vercel.app/addnews', {
                        method: 'POST',
                        headers: {
                            'Content-type': 'application/json',
                            authorization: `bearer ${localStorage.getItem('accessToken')}`
                        },
                        body: JSON.stringify(news)
                    })
                        .then(res => res.json())
                        .then(result => {
                            console.log(result);
                            toast.success(`${data.title} is added successfully`);
                            navigate('/dashboard/managenews');
                        })
                }
            })
    }

    return (
        <div className='w-96 p-7'>
            <h1>Add New News/Articles</h1>

            <form onSubmit={handleSubmit(handleAddNews)}>
                <div className="form-control w-full max-w-xs">
                    <label className="label"><span className="label-text">Title</span></label>
                    <input type="text" {...register("name", {
                        required: "Name is Required"
                    })} className="input input-bordered w-full max-w-xs" />
                    {errors.name && (<p className="text-red-600">{errors.name?.message}</p>)}
                </div>

                <div className="form-control w-full max-w-xs">
                    <label className="label"><span className="label-text">News In Details</span></label>

                    <textarea type="text" {...register("news", {
                        required: "News is Required"
                    })} className="textarea textarea-bordered w-full max-w-xs" />
                    {errors.news && (<p className="text-red-600">{errors.news?.message}</p>)}
                </div>
                {/* <div className="form-control w-full max-w-xs">
                    <label className="label"><span className="label-text">News Photo</span></label>
                    <input type="file" {...register("image", {
                        required: "Photo is Required"
                    })} className="input input-bordered w-full max-w-xs" />
                    {errors.image && (<p className="text-red-600">{errors.image?.message}</p>)}
                </div> */}

                <div className="form-control w-full max-w-xs flex items-center justify-center w-full">
                    <label className="label"><span className="label-text">News Photo</span></label>
                    <label for="dropzone-file" className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                        <div className="flex flex-col items-center justify-center">
                            <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                        </div>
                        {/* <input id="dropzone-file" type="file" className="hidden" /> */}
                        <input type="file" {...register("image", {
                            required: "Photo is Required"
                        })} className="input input-bordered w-full max-w-xs hidden" id="dropzone-file" />
                    </label>
                </div>

                <input className="btn btn-accent w-full mt-4" value="Add News" type="submit" />
                <div>
                    {/* {signUpError && <p className='text-red-600'>{signUpError}</p>} */}
                </div>
            </form>
        </div>
    );
};

export default AddNews;