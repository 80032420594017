import React from 'react';
import FindRescCard from './FindRescCard';

const FindResources = () => {
    const cardData = [
        {
            id: 1,
            name: 'A. Know your rights',
            description: `Every citizen should enjoy equal justice without discrimination based on race, color or relegion. It's critical that we understand the laws and  court system that determine those rights. Learn about the laws and structures that shape our everyday lives.`,
            bgClass: 'bg-gradient-to-r from-primary to-secondary'
        },
        {
            id: 2,
            name: 'B. Education programs and Workshop',
            description: 'Find regular programs, events, and workshops from  local JHD, chapter, or unit to grow your community.',
     
            bgClass: 'bg-accent'
        },
        {
            id: 3,
            name: 'C. The Youth',
            description: 'The next generation of Human rights leaders is making history now. Young people under the age of 25 are leading change through youth councils, involving in JHD events and getting actively involved in the fight for civil and social justice.The next generation Human rights leaders find a home and support at MUNA JH.',
   
            bgClass: 'bg-gradient-to-r from-primary to-secondary'
        }
    ]
    return (
        <div className='mt-16' id='success'>
            <div className='text-center'>
                <h3 className='text-xl font-bold text-primary uppercase underline'>Find Resources</h3>
                <h2 className='text-3xl'>Donate to charity causes
                    around the world</h2>
            </div>

            <div className='grid mt-8 gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                {
                    cardData.map(card => <FindRescCard
                        key={card.id}
                        card={card}
                    ></FindRescCard>)
                }
            </div>
        </div>
    );
};

export default FindResources;