import React from 'react';
import { useLocation } from 'react-router-dom';
const ReciveData = () => {
    const { state } = useLocation();
    const { name, email, price } = state;

    return (
        <section className='mt-10'>
            <div className="card w-full bg-base-100 shadow-xl">
                <figure><img className="object-cover" src={price} alt="Shoes" /></figure>
                <div className="card-body text-center">
                    <p>{name}</p>
                    <p>{email}</p>
                </div>
            </div>
        </section>
    );
};

export default ReciveData;