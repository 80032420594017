import React from 'react';
import ActivityWeDo from '../ActivityWeDo/ActivityWeDo';
import History from '../History/History';
import TopBannerWeDo from '../TopBannerWeDo/TopBannerWeDo';

const WhatWeDo = () => {
    return (
        <div className="mx-5">
            <TopBannerWeDo></TopBannerWeDo>
            <ActivityWeDo></ActivityWeDo>
            <History></History>
        </div>
    );
};

export default WhatWeDo;