import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
const DonationAmount = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigation = useNavigate();
    const handleSignUp = (data) => {
        console.log(data.email, data.password);
        navigation('/processdonation', { state: {name:data.name, email: data.email, price: data.price} });
    }
    return (
        <div className="h-[500px] flex justify-center items-center">
            <div className="w-96 p-7">
                <h2 className="text-xl text-center">Provide Donation Amount With Details</h2>
                <form onSubmit={handleSubmit(handleSignUp)}>
                    <div className="form-control w-full max-w-xs">
                        <label className="label"><span className="label-text">Name</span></label>
                        <input type="text" {...register("name", {
                            required: "Name is Required"
                        })} className="input input-bordered w-full max-w-xs" />
                        {errors.name && (<p className="text-red-600">{errors.name?.message}</p>)}
                    </div>
                    <div className="form-control w-full max-w-xs">
                        <label className="label"><span className="label-text">Email</span></label>
                        <input type="email" {...register("email", {
                            required: "Email Address is Required",
                        })}
                            className="input input-bordered w-full max-w-xs" />
                        {errors.email && (<p className="text-red-600">{errors.email?.message}</p>)}
                    </div>
                    <div className="form-control w-full max-w-xs">
                        <label className="label"><span className="label-text">Donation Amount</span></label>
                        <input type="number" {...register("price", {
                            valueAsNumber: true,
                            validate: (value) => value > 0,
                            required: "Amount is required",

                        })}

                            className="input input-bordered w-full max-w-xs" />
                        {errors.price && <p className='text-red-600'>{errors.price?.message}</p>}
                    </div>
                    <input className="btn btn-accent w-full mt-4" value="Donate" type="submit" />
                    <div>
                        {/* {signUpError && <p className='text-red-600'>{signUpError}</p>} */}
                    </div>
                </form>

            </div>
        </div>
    );
};

export default DonationAmount;