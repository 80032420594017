import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import Loading from '../../Shared/Loading/Loading';
import { toast } from 'react-hot-toast';

const ManageContact = () => {
    const [deletingContact, setDeletingContact] = useState(null);
    const closeModal = () => {
        setDeletingContact(null);
    }
    
    const { data: contact, isLoading, refetch } = useQuery({
        queryKey: ['contact'],
        queryFn: async (query) => {
            try {
                const res = await fetch('https://jhd-donation-mahsin-islam.vercel.app/fetchcontact', {
                    headers: {
                        authorization: `bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                const data = await res.json();
                console.log("dats->",contact)
                return data;
            }
            catch (err) {

            }
        }
    });

    const handleDeleteDoctor = contactData => {
        fetch(`https://jhd-donation-mahsin-islam.vercel.app/deletecontact/${contactData._id}`, {
            method: 'DELETE', 
            headers: {
                authorization: `bearer ${localStorage.getItem('accessToken')}`
            }
        })
        .then(res => res.json())
        .then(data => {
            if(data.deletedCount > 0){
                refetch();
                toast.success(`Contact ${contactData.email} details deleted successfully`)
            }
        })
    }

    if (isLoading) {
        return <Loading></Loading>
    }
    return (
        <div>
            <h2 className="text-3xl">Manage Contact: {contact?.length}</h2>
            <div className="overflow-x-auto">
                <table className="table w-full">

                    <thead>
                        <tr>
                            <th></th>
                            <th>Email</th>
                            <th>Subject</th>
                            <th>Message</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            contact.map((contactData, i) => <tr key={contactData._id}>
                                <th>{i + 1}</th>
                                <td>{contactData.email}</td>
                                <td>{contactData.subject.substring(0,40)}</td>
                                <td>{contactData.message.substring(0,40)}</td>
                                <td>
                                    <label onClick={() => setDeletingContact(contactData)}  htmlFor="confirmation-modal" className="btn btn-sm btn-error">Delete</label>
                                </td>
                            </tr>)
                        }

                    </tbody>
                </table>
            </div>
            {
                deletingContact && <ConfirmationModal
                title={`Are you sure you want to delete?`}
                message={`If you delete ${deletingContact.email}. It cannot be undone.`}
                successAction={handleDeleteDoctor}
                successButtonName="Delete"
                modalData = {deletingContact}
                closeModal = {closeModal}
                >
                </ConfirmationModal>
            }
        </div>
    );
};

export default ManageContact;