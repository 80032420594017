import React from 'react';
import chair from '../../../assets/images/chair.png';
import slider1 from '../../../assets/images/slider/slider-masjid.jpg';
import slider2 from '../../../assets/images/slider/slider-quran-1.jpg';
import slider3 from '../../../assets/images/slider/slider-zakat.jpg';
import bg from '../../../assets/images/bg.png';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const Banner = () => {
    return (
        // <div className="hero min-h-screen"
        // style={{
        //     backgroundImage: `url(${bg})`
        //   }}
        //   >
        //     <div className="hero-content flex-col lg:flex-row-reverse">
        //         <img src={chair} className="rounded-lg lg:w-1/2 shadow-2xl" alt="" />
        //         <div>
        //             <h1 className="text-5xl font-bold">Your New Smile Starts Here!</h1>
        //             <p className="py-6">Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda excepturi exercitationem quasi. In deleniti eaque aut repudiandae et a id nisi.</p>
        //             <PrimaryButton>Get Started</PrimaryButton>
        //         </div>
        //     </div>
        // </div>
        <>
        <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
        >
            <SwiperSlide>
                <img
                    className="object-fill w-full h-[32rem] rounded"
                    src={slider1}
                    alt="image slide 1"
                />
            </SwiperSlide>
            <SwiperSlide>
                <img
                    className="object-fill w-full h-[32rem] rounded"
                    src={slider2}
                    alt="image slide 2"
                />
            </SwiperSlide>
            <SwiperSlide>
                <img
                    className="object-fill w-full h-[32rem] rounded"
                    src={slider3}
                    alt="image slide 3"
                />
            </SwiperSlide>
        </Swiper>
    </>
    );
};

export default Banner;