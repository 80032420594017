import React from 'react';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import appointment from '../../../assets/images/appointment.png';
import bgConnected from '../../../assets/images/background/bg-connected.jpg';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
const ContactUs = () => {
    const { register, handleSubmit, reset , formState: { errors } } = useForm();
    const navigate = useNavigate();

    const handleAddContactDetails = data => {
        const contactInfo = {
            email: data.email,
            subject: data.subject,
            message: data.message,
        }
        // save doctor information to the database
        fetch('https://jhd-donation-mahsin-islam.vercel.app/savedcontact', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                authorization: `bearer ${localStorage.getItem('accessToken')}`
            },
            body: JSON.stringify(contactInfo)
        })
            .then(res => res.json())
            .then(result => {
                console.log(result);
                toast.success(`${data.email} details contact sent successfully`);
                reset();
                navigate('/');
            })
    }
    return (
        <div style={{
            background: `url(${bgConnected})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }} className='bg-primary px-10 py-14 rounded-md'>
            <div className='text-center pb-14 text-white'>
                <p className='text-xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-accent to-secondary'>
                    Contact Us
                </p>
                <h1 className='text-4xl'>Stay connected with us</h1>
            </div>
            <form onSubmit={handleSubmit(handleAddContactDetails)}>
                <div className='grid grid-cols-1 justify-items-center gap-5'>

                    <input type="email" {...register("email", {
                        required: "Email is Required"
                    })} className="input input-bordered w-full max-w-md" placeholder='Email Address' />
                    {errors.email && (<p className="text-red-600">{errors.email?.message}</p>)}

                    <input type="test" {...register("subject", {
                        required: "Subject is Required"
                    })} className="input input-bordered w-full max-w-md" placeholder='Subject' />
                    {errors.subject && (<p className="text-red-600">{errors.subject?.message}</p>)}

                    <textarea placeholder='Your message' rows={6} type="text" {...register("message", {
                        required: "Message is Required"
                    })} className="textarea textarea-bordered w-full max-w-md" />
                    {errors.message && (<p className="text-red-600">{errors.message?.message}</p>)}
                    <PrimaryButton>Submit</PrimaryButton>

                </div>
            </form>
        </div>
    );
};

export default ContactUs;