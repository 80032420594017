import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import React from 'react';
import { useLoaderData, useNavigation, useLocation } from 'react-router-dom';
// import Loading from '../../Shared/Loading/Loading';
import StripeCheckoutForm from './StripeCheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
const ProcessDonation = () => {
    const { state } = useLocation();
    const navigation = useNavigation();
    const { name, email, price } = state;


    // if(navigation.state === "loading"){
    //         return <Loading></Loading>
    //  }

    return (
        <div className='text-center'>
            {/* <h3 className="text-3xl">Payment by {name} email: {email}</h3>
            <p className="text-xl">Donate amount <strong>${price}</strong> for charity </p> */}

            <div tabIndex={0} className="collapse border border-base-300 bg-base-100 rounded-box mt-6">
                <div className="collapse-title text-xl font-medium">
                Payment by {name} email: {email}
                </div>
                <div >
                    <p>Donate amount <strong>${price}</strong> for charity </p>
                </div>
            </div>
            <div className="w-full my-12">
                <Elements stripe={stripePromise}>
                    <StripeCheckoutForm
                        booking={state}
                    />
                </Elements>
            </div>
        </div>
    );
};

export default ProcessDonation;