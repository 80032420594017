import React from 'react';
import { Link } from 'react-router-dom';
import appointment from '../../../assets/images/appointment.png'
import muslimCHarity from '../../../assets/images/charity/muslim-charity.png'
import muslimCHarity02 from '../../../assets/images/charity/charity-02.PNG';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
const TopBannerWeDo = () => {
    return (
        <section className='mt-5'
        style={{
            background: `url(${appointment})`,
            borderRadius: '10px',
        }}
            >
            <div className="hero" id="aboutus">
                <div className="hero-content flex-col lg:flex-row">
                    <img src={muslimCHarity02} alt="" className="-mt-15 hidden lg:block md:block lg:w-1/2 rounded-full" />
                    <div>
                        <h4 className='text-lg text-primary font-bold'>Wha We Do</h4>
                        <h1 className=" text-white text-xl font-bold">We’re worldwide non-profit charity ogranization.</h1>
                        <p className="text-white py-4">MUNA JHD is dedicated to raise awareness against injustices and protect human rights and dignity around the world. We identify, investigate  and expose injustices happening in all corners of the world. We are a group of concerned citizens around the nation working to protect the most at risk, minorities  and civilians who face injustices. We work with all walks of people, backgrounds  and organizations to campaign for justice for all regardless of their race, color, religion or political background. Our primary focus of the work includes conducting research, exposing injustice through the media, providing support to victims, raising awareness through seminars/symposiums, and advocating with government officials as well as international organizations.</p>
                        <Link to='/stripedonate'><PrimaryButton>Make Donation</PrimaryButton></Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopBannerWeDo;