import React from 'react';
import OurActivities from '../OurActivities/OurActivities';
import TopBannerSuccess from '../TopBannerSuccess/TopBannerSuccess';

const SuccessStories = () => {
    return (
        <div>
            <TopBannerSuccess></TopBannerSuccess>
            <OurActivities></OurActivities>
        </div>
    );
};

export default SuccessStories;