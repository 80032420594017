import React, { useContext } from 'react';
import { AuthContext } from '../../../contexts/AuthProvider';
import { useQuery } from "@tanstack/react-query";
import { Link } from 'react-router-dom';
const MyDonation = () => {
    const {user} = useContext(AuthContext);
    const url = `https://jhd-donation-mahsin-islam.vercel.app/stripepaymentslist?email=${user?.email}`;

    const { data: bookings = [] } = useQuery({
        queryKey: ['bookings', user?.email],
        queryFn: async() => {
            const res = await fetch(url,{
                headers: { 
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            const data = await res.json();
            return data
          }
      })
    return (
        <div>
            <h3 className="text-3xl mb-5">Donation Amount List</h3>
            <div className="overflow-x-auto">
                <table className="table w-full">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Email</th>
                            <th>TransactionID</th>
                            <th>Amount</th>
                            <th>Payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            bookings &&
                            bookings?.map((booking, i) => <tr key={booking._id}>
                                <th>{i + 1}</th>
                                <td>{booking.email}</td>
                                <td>{booking.transactionId}</td>
                                <td>{booking.price}</td>
                                <td>
                                
                                    {
                                        booking.price &&  <span className="text-green-500">Paid</span>
                                    } 

                                </td>
                            </tr>)
                        }

                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MyDonation;