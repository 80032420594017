import React from 'react';
import { Link } from 'react-router-dom';
import nuslimBackground from '../../../assets/images/background/bg-islamic.png';
import muslimCHarity02 from '../../../assets/images/charity/islamicsuccessone.PNG';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
const TopBannerVolunteer = () => {
    return (
        <section className='mt-5'
            style={{
                background: `url(${nuslimBackground})`,
                borderRadius: '10px',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className="hero" id="aboutus">
                <div className="hero-content flex-col lg:flex-row">
                    <img src={muslimCHarity02} alt="" className="-mt-15 hidden lg:block md:block lg:w-1/2 rounded-full" />
                    <div className='mb-20'>
                        <h4 className='text-2xl text-primary font-bold underline mt-10'>Volunteer</h4>
                        <h1 className=" text-white text-xl ">We have helped many organizations to fight for justice including</h1>
                        <p className="text-white py-2">There are many ways in which you can participate – what type of work, the amount of time you want to spend, is entirely up to you. The types of volunteering are listed below.</p>
                        <p className="text-white py-2">
                        Please look carefully to see if we are currently looking for that type of volunteer. If not, please keep chec any box  or send us an email asking us to let you know when such an opportunity arises. MUNA JHD  feels that it is important that volunteers enjoy what they are doing – it is more productive for you, and more productive for JHD.
                        </p>
                      
                        <Link to='/stripedonate'><PrimaryButton>Make Donation</PrimaryButton></Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopBannerVolunteer;