import React from 'react';
import treatment from '../../../assets/images/treatment.png';
import charityOne from '../../../assets/images/charity/charity-one.png';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import { Link } from 'react-router-dom';
const DentalCare = () => {
    return (
        <div className="hero my-20">
            <div className="hero-content flex-col lg:flex-row">
                <figure>
                    <img src={charityOne} className="max-w-sm rounded-lg shadow-2xl" alt='treatment' />
                </figure>
                <div className="px-8">
                    <h1 className="text-5xl font-bold">Exceptional Mission of MUNA JHD</h1>
                    <p className="py-8">We campaign for justice and human dignity around the world  through awareness, research and advocacy. We identify and expose injustice wherever it arises. We create spaces for learning, connecting and cultivating solutions to advance equal justice.</p>
                    <Link to='/stripedonate'><PrimaryButton>Get Donate</PrimaryButton></Link>
                </div>
            </div>
        </div>
    );
};


export default DentalCare;