import React from 'react';
import FindResources from '../FindResources/FindResources';
import TopBannerVolunteer from '../TopBannerVolunteer/TopBannerVolunteer';
import WaysToVolunteer from '../WaysToVolunteer/WaysToVolunteer';

const GetInvolved = () => {
    return (
        <div className="mx-5">
            <TopBannerVolunteer></TopBannerVolunteer>
            <FindResources></FindResources>
            <WaysToVolunteer></WaysToVolunteer>
        </div>
    );
};

export default GetInvolved;