import React from 'react';
import { useNavigate } from 'react-router-dom';

const Service = ({ service }) => {
    const { name, news, image } = service;
    const navigation = useNavigate();
    const handleServiceData = (service) => {
        console.log(service);
        navigation('/processService', { state: { name: name, email: news, price: image } });
    }
    return (
        <div className="card w-96 bg-base-100 shadow-xl">
            <figure className="px-10 pt-10">
                <img src={image} alt="Shoes" className="rounded-xl" />
            </figure>
            <div className="card-body items-center text-center">
                <h2 className="card-title">{name.substring(0,30)}</h2>
                <p>{news.substring(0,40)}</p>
                <button onClick={() => handleServiceData(service)} data-mdb-ripple="true" data-mdb-ripple-color="light"
                    className="inline-block px-6 py-2.5 bg-green-400 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out">Read
                    more</button>
            </div>
        </div>
    );
};

export default Service;