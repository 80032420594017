import React from 'react';
import doctor from '../../../assets/images/doctor.png'
import appointment from '../../../assets/images/appointment.png'
import muslimCHarity from '../../../assets/images/charity/muslim-charity.png'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import { Link } from 'react-router-dom';

const MakeAppointment = () => {
    return (
        <section className='mt-32'
        style={{
            background: `url(${appointment})`,
            borderRadius: '10px',
        }}
            >
            <div className="hero" id="aboutus">
                <div className="hero-content flex-col lg:flex-row">
                    <img src={muslimCHarity} alt="" className="-mt-15 hidden lg:block md:block lg:w-1/2 rounded-full" />
                    <div>
                        <h4 className='text-lg text-primary font-bold'>About MUNA JHD</h4>
                        <h1 className=" text-white text-4xl font-bold">We’re worldwide non-profit charity ogranization.</h1>
                        <p className="text-white py-6">Each and every JHD member makes a difference to the complex, ongoing work of advancing racial equity. We have driven the hardest-fought wins for civil rights and social justice — with you by our side, we can accelerate the next milestones.</p>
                        <Link to='/stripedonate'><PrimaryButton>Make Donation</PrimaryButton></Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MakeAppointment;