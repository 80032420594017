import React from 'react';
import cause from '../../../assets/images/causes/cause-1-1.png';
import charityOne from '../../../assets/images/charity/charity-one.png';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import { Link } from 'react-router-dom';
const History = () => {
    return (
        <div className="hero my-15">
            <div className="hero-content flex-col lg:flex-row">
                <figure>
                    <img src={cause} className="max-w-sm rounded-lg shadow-2xl" alt='treatment' />
                </figure>
                <div className="px-10">
                    <h1 className="text-3xl font-bold text-primary">History</h1>
                    <p className="py-4">We started this journey of fighting against injustices and upholding human dignity almost 2 decades ago. MUNA JHD  strated soon after the organization was established 1992. MUNA JHD started very informally with only a few concerned citizens affiliated with MUNA  began seeking additional information on immigrants rights and human rights in general  here and abroad.  Since then, our work has expanded to 32 states here in the USA. We researched and investigated massacres and even human rights violations abroad , along with government take-overs of the media and the baseless arrests of activists and political opposition figures overseas. At the same time, we expanded our work to address abuses against those likely to face injustices through discriminatory practices,, including immigransts, and people of color. Now our work has expanded and goes beyond the single scope of Immigrants  rights to our current expanded focus of equal justice and dignity  for all and we make it possible through conducting research, exposing injustice through the media, providing support to victims, raising awareness through seminar/symposium, and advocate with government officials and international organizations.</p>
                    <h1 className="text-2xl font-bold text-secondary">Report an incident </h1>
                    <p>Please try to fill in as much detail as possible. All information is treated in the strictest confidence and no information will be passed on to any third party without your consent. For more information, please contact Zia-ul Shamim 586-244-3304 MUNA-JHD@MUNA.org</p>
                    <div className="mt-3">
                        <Link to='/stripedonate'><PrimaryButton>Get Donate</PrimaryButton></Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default History;