import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import fluoride from '../../../assets/images/causes/cause-1-1.png'
import cavity from '../../../assets/images/causes/cause-1-3.jpg'
import whitening from '../../../assets/images/causes/cause-1-3.png'
import Service from './Service';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay } from "swiper";
import Loading from '../../Shared/Loading/Loading';

const Services = () => {

    const servicesData = [
        {
            id: 1,
            name: 'Our donation is hope for poor childrens',
            description: 'Lorem Ipsum is simply dummy printing and typesetting indust Ipsum has been the',
            img: fluoride
        },
        {
            id: 2,
            name: 'Education for Poor Children',
            description: 'Lorem Ipsum is simply dummy printing and typesetting indust Ipsum has been the',
            img: cavity
        },
        {
            id: 3,
            name: 'Promoting The Rights of Children',
            description: 'Lorem Ipsum is simply dummy printing and typesetting indust Ipsum has been the',
            img: whitening
        },
        {
            id: 4,
            name: 'Promoting The Rights of Children',
            description: 'Lorem Ipsum is simply dummy printing and typesetting indust Ipsum has been the',
            img: whitening
        },
        {
            id: 5,
            name: 'Promoting The Rights of Children',
            description: 'Lorem Ipsum is simply dummy printing and typesetting indust Ipsum has been the',
            img: whitening
        },
    ]


    const { data: news, isLoading, refetch } = useQuery({
        queryKey: ['news'],
        queryFn: async (query) => {
            try {
                const res = await fetch('https://jhd-donation-mahsin-islam.vercel.app/news', {
                    headers: {
                        authorization: `bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                const data = await res.json();
                console.log("news->",data)
                return data;
            }
            catch (err) {

            }
        }
    });

    const [swipers, setSwipers] = useState([
        "Slide 1",
        "Slide 2",
        "Slide 3",
        "Slide 4"
    ]);

    const addSwiper = (swiper) => {
        setSwipers((prevSwipers) => prevSwipers.concat(swiper));
    };
    if (isLoading) {
        return <Loading></Loading>
    }
    return (
        <div className='mt-16' id='success'>
            <div className='text-center'>
                <h3 className='text-xl font-bold text-primary uppercase'>Popular News/Articles</h3>
                <h2 className='text-3xl'>Donate to charity causes
                    around the world</h2>
            </div>
            {/* <div className='grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                {
                    servicesData.map(service => <Service
                        key={service.id}
                        service={service}
                    ></Service>)
                }
            </div> */}

            <>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={3}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}

                    modules={[Autoplay]}

                >
                    {
                        news.map((newsData, index) => (
                            <SwiperSlide key={index}>
                                <Service
                                    key={newsData._id}
                                    service={newsData}
                                >
                                </Service>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </>
        </div>
    );
};

export default Services;