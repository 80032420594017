import React from 'react';
import { Link } from 'react-router-dom';
import appointment from '../../../assets/images/appointment.png'
import muslimCHarity from '../../../assets/images/charity/muslim-charity.png';
import nuslimBackground from '../../../assets/images/background/bg-islamic.png';
// import muslimCHarity02 from '../../../assets/images/charity/charity-02.PNG';
// import muslimCHarity02 from '../../../assets/images/charity/islamicsuccess.PNG';
import muslimCHarity02 from '../../../assets/images/charity/islamicsuccessone.PNG';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
const TopBannerSuccess = () => {
    return (
        <section className='mt-5'
            style={{
                background: `url(${nuslimBackground})`,
                borderRadius: '10px',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className="hero" id="aboutus">
                <div className="hero-content flex-col lg:flex-row">
                    <img src={muslimCHarity02} alt="" className="-mt-15 hidden lg:block md:block lg:w-1/2 rounded-full" />
                    <div className='mb-20'>
                        <h4 className='text-2xl text-primary font-bold underline mt-10'>Success Stories</h4>
                        <h1 className=" text-white text-xl ">We have helped many organizations to fight for justice including</h1>
                        <p className="text-white py-2">Palestine issues, BDR massacre in bangladesh, wrongful conviction and immigrant rights. We document the conditions of their imprisonment, take their stories to hundreds  of people, and apply pressure on global leaders and lawmakers</p>
                        <p className="text-white py-2">
                            MUNA JHD  has been active in advocacy regarding Muslim dress in various states in USA. In addition, we strongly supported legal fight against muslim ban and acheived sucess at various level.
                        </p>
                        <p className="text-white py-2">
                            We worked with MUNA and local organizations and negotiated on behalf of parents with a number of schools so that the school would incorporate the Halal food into their lunch menu. We supported numerous clients to challenge their employers when they were asked to remove the hijab or were discriminated against because they wear the hijab.
                        </p>
                        <Link to='/stripedonate'><PrimaryButton>Make Donation</PrimaryButton></Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopBannerSuccess;