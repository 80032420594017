import {
    createBrowserRouter
} from "react-router-dom";
import DashboardLayout from "../../Layout/DashboardLayout";
import Main from "../../Layout/Main";
import Appointment from "../../Pages/Appointment/Appointment/Appointment";
import AllUsers from "../../Pages/Dashboard/AllUsers/AllUsers";

import Home from "../../Pages/Home/Home/Home";
import Login from "../../Pages/Login/Login";
import SignUp from "../../Pages/SignUp/SignUp";
import DonationAmount from "../../Pages/StripeDonate/DonationAmount";
import AdminRoute from "../AdminRoute/AdminRoute";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import ProcessDonation from "../../Pages/StripeDonate/ProcessDonation";
import MyDonation from "../../Pages/Dashboard/MyDonation/MyDonation";
import WhatWeDo from "../../Pages/WhatWeDo/WhatWeDo/WhatWeDo";
import SuccessStories from "../../Pages/SuccessStories/SuccessStories/SuccessStories";
import ReciveData from "../../Pages/Home/ReciveData/ReciveData";
import AddNews from "../../Pages/Dashboard/AddNews/AddNews";
import ManageNews from "../../Pages/Dashboard/ManageNews/ManageNews";
import GetInvolved from "../../Pages/GetInvolved/GetInvolved/GetInvolved";
import ManageContact from "../../Pages/Dashboard/ManageContact/ManageContact";
const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
        children: [
            {
                path: "/",
                element:  <Home></Home>
            },
            {
                path: "/login",
                element: <Login></Login>
            },
            {
                path: "/signup",
                element: <SignUp></SignUp>
            },
            {
                path: "/appointment",
                element: <Appointment></Appointment>
            },
            {
                path: "/stripedonate",
                element: <DonationAmount></DonationAmount>
            },
            {
                path: "/processdonation",
                element: <ProcessDonation></ProcessDonation>
            },
            {
                path: "/processService",
                element: <ReciveData></ReciveData>
            }
        ]
    },
    {
        path: "/whatwedo",
        element: <Main />,
        children: [
            {
                path: "/whatwedo",
                element:  <WhatWeDo></WhatWeDo>
            }
        ]
    },
    {
        path: "/getinvolved",
        element: <Main />,
        children: [
            {
                path: "/getinvolved",
                element:  <GetInvolved></GetInvolved>
            }
        ]
    },
    {
        path: "/successstories",
        element: <Main />,
        children: [
            {
                path: "/successstories",
                element:  <SuccessStories></SuccessStories>
            }
        ]
    },
    {
        path: '/dashboard',
        element: <PrivateRoute><DashboardLayout></DashboardLayout></PrivateRoute>,
        children:[
            {
                path: '/dashboard',
                element: <MyDonation></MyDonation>
            },
            {
                path: '/dashboard/allusers',
                element: <AdminRoute><AllUsers></AllUsers></AdminRoute>
            },
            {
                path: '/dashboard/addnews',
                element: <AdminRoute><AddNews></AddNews></AdminRoute>
            },
            {
                path: '/dashboard/managenews',
                element: <AdminRoute><ManageNews></ManageNews></AdminRoute>
            },
            {
                path: '/dashboard/managecontact',
                element: <AdminRoute><ManageContact></ManageContact></AdminRoute>
            },
        ]
    }
])

export default router;