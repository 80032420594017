import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import Loading from '../../Shared/Loading/Loading';
import { toast } from 'react-hot-toast';
const ManageNews = () => {
    const [deletingNews, setDeletingNews] = useState(null);
    const closeModal = () => {
        setDeletingNews(null);
    }
    
    const { data: news, isLoading, refetch } = useQuery({
        queryKey: ['news'],
        queryFn: async (query) => {
            try {
                const res = await fetch('https://jhd-donation-mahsin-islam.vercel.app/managenews', {
                    headers: {
                        authorization: `bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                const data = await res.json();
                return data;
            }
            catch (err) {

            }
        }
    });

    const handleDeleteDoctor = newsData => {
        fetch(`https://jhd-donation-mahsin-islam.vercel.app/deletenews/${newsData._id}`, {
            method: 'DELETE', 
            headers: {
                authorization: `bearer ${localStorage.getItem('accessToken')}`
            }
        })
        .then(res => res.json())
        .then(data => {
            if(data.deletedCount > 0){
                refetch();
                toast.success(`Doctor ${newsData.name} deleted successfully`)
            }
        })
    }

    if (isLoading) {
        return <Loading></Loading>
    }
    return (
        <div>
            <h2 className="text-3xl">Manage News/Articles: {news?.length}</h2>
            <div className="overflow-x-auto">
                <table className="table w-full">

                    <thead>
                        <tr>
                            <th></th>
                            <th>Image</th>
                            <th>Title</th>
                            <th>News Details</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            news.map((newsData, i) => <tr key={newsData._id}>
                                <th>{i + 1}</th>
                                <td><div className="avatar">
                                    <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                                        <img src={newsData.image} />
                                    </div>
                                </div></td>
                                <td>{newsData.name}</td>
                                <td>{newsData.news.substring(0,40)}</td>
                                <td>
                                    <label onClick={() => setDeletingNews(newsData)}  htmlFor="confirmation-modal" className="btn btn-sm btn-error">Delete</label>
                                </td>
                            </tr>)
                        }

                    </tbody>
                </table>
            </div>
            {
                deletingNews && <ConfirmationModal
                title={`Are you sure you want to delete?`}
                message={`If you delete ${deletingNews.name}. It cannot be undone.`}
                successAction={handleDeleteDoctor}
                successButtonName="Delete"
                modalData = {deletingNews}
                closeModal = {closeModal}
                >
                </ConfirmationModal>
            }
        </div>
    );
};

export default ManageNews;