
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';



const StripeCheckoutForm = ({ booking }) => {
  const [cardError, setCardError] = useState('');
  const [success, setSuccess] = useState('');
  const [processing, setProcessing] = useState(false);
  const [transactionId, setTransactionId] = useState('');

  const [clientSecret, setClientSecret] = useState("");

  const stripe = useStripe();
  const elements = useElements();
  const { name, email, price } = booking;
  console.log("booking-->", price);
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("https://jhd-donation-mahsin-islam.vercel.app/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      body: JSON.stringify({ price }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, [price]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardElement);

    if (card == null) {
      return;
    }

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card,
    });

    if (error) {
      console.log('[error]', error);
      setCardError(error.message);
    } else {
      setCardError('');
      console.log('[PaymentMethod]', paymentMethod);
    }
    setSuccess('');
    setProcessing(true);


    const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: card,
          billing_details: {
            name: name,
            email: email
          },
        },
      },
    );

    if (confirmError) {
      setCardError(confirmError.message);
      return;
    }


    if (paymentIntent.status === "succeeded") {

      console.log('card info', card);
      console.log("transactionid", paymentIntent.id)
      // store payment information in database
      const payment = {
        price,
        transactionId: paymentIntent.id,
        email
        //   bookingId: _id
      }
      fetch('https://jhd-donation-mahsin-islam.vercel.app/payments', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          // authorization: `Bearer ${localStorage.getItem('accessToken')}`
        },
        body: JSON.stringify(payment)
      })
        .then(res => res.json())
        .then(data => {
          console.log(data);
          if (data.insertedId) {
            setSuccess('Congrats! You payment completed');
            setTransactionId(paymentIntent.id);
          }
        })
    }
    setProcessing(false);

  }

  return (
    <>
      <form onSubmit={handleSubmit} className="h-[200px] flex justify-center items-center">
        <div className="w-96 p-7">
          <div className="input input-bordered input-accent w-full max-w-xs mt-2" >
            <CardElement className='mt-3'
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </div>


          <button
            className="form-control btn btn-sm mt-4 btn-primary text-center"
            type="submit"
          >
            Donate
          </button>
        </div>

      </form>
      <div className="flex justify-center items-center">

        <div className="max-w-[50%] p-7">

          {
            cardError && <div className="alert alert-error shadow-lg mt-6">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>{cardError}</span>
              </div>
            </div>
          }


          {
            success &&

            <div className="alert alert-success shadow-lg mt-6">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>{success}</span>
                <p>Your transactionId: <span className="font-bold">{transactionId}</span></p>
              </div>
            </div>
          }
        </div>

      </div>
    </>
  );
};

export default StripeCheckoutForm;